bui<template>
  <Layout class="Dashboard-layout">
    <BHeader>
      <el-form
        ref="searchForm"
        :model="formData"
        :rules="rules"
        :inline="true"
        size="mini"
      >
        <el-form-item prop="dateRange">
          <el-date-picker
            v-model="formData.dateRange"
            :picker-options="pickerOptions"
            type="datetimerange"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="formData.countryCodeList"
            style="width: 240px"
            multiple
            clearable
            filterable
            :filter-method="filterMethod"
            collapse-tags
            value-key="countryCode"
            placeholder="Country"
            @visible-change="filterNationList = nationList"
          >
            <div class="pre-header">
              <div
                class="select-all"
                @click="changeSelectState('countryCodeList', nationList, formData)"
              >
                {{ selectButtonLabel(formData.countryCodeList, nationList) }}
              </div>
            </div>
            <el-option
              v-for="item in filterNationList"
              :key="item.countryCode"
              :label="item.nation"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="region">
          <el-input
            v-model="formData.region"
            placeholder="区域"
            clearable
          />
        </el-form-item>
        <el-form-item prop="idcId">
          <el-input
            v-model="formData.idcId"
            placeholder="idc_id"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch()"
          >
            查询
          </el-button>
        </el-form-item>
      </el-form>
      <template #right>
        <el-tooltip
          :content="idcRoomsInfo"
          placement="top"
        >
          <div class="change-icon">
            <i class="el-icon-question" />
          </div>
        </el-tooltip>
      </template>
    </BHeader>
    <el-table
      v-loading="tableData.loading"
      :data="tableData.list"
      style="width: 100%"
    >
      <el-table-column
        prop="idc_id"
        label="idc_id"
      />
      <el-table-column
        prop="nation"
        label="国家"
      />
      <el-table-column
        prop="region"
        label="区域"
      />
      <el-table-column
        prop="client_ip"
        label="客户端_ip"
      />
      <el-table-column
        prop="delay"
        label="延迟"
      />
      <el-table-column
        prop="create_time"
        label="创建时间"
      />
      <el-table-column
        prop="update_time"
        label="更新时间"
      />
    </el-table>
    <Pagination
      v-show="!tableData.loading"
      :total="tableData.total"
      :page.sync="formData.page"
      :limit.sync="formData.size"
      @pagination="queryDataList"
    />
  </Layout>
</template>

<script>
import { format } from 'fecha'
import { countryCodeList } from '@/utils/selectOptions'
import { cloneDeep } from 'lodash'
import { querySpeedMeasurementDelay } from '@/api/product-operation.js'
import { getUserNationStatistics } from '@/api/electronicEntity.js'
const dateFormat = 'YYYY-MM-DD HH:mm:ss'
const countryCodeMap = countryCodeList.reduce((map, item) => {
  map[item.name] = item
  return map
}, {})
const formKey = 'speed-measurement-delay-form'
export default {
  name: 'Dashboard',
  data () {
    return {
      filterNationList: [],
      nationList: [],
      countryCodeList,
      countryCodeMap,
      tableData: {
        loading: false,
        total: 0,
        idcRooms: [],
        list: []
      },
      formData: {
        dateRange: [
          format(new Date().getTime() - 86400 * 1000, dateFormat),
          format(new Date().getTime(), dateFormat)
        ],
        page: 1,
        size: 20,
        region: '',
        idcId: '',
        countryCodeList: []
      },
      rules: {
        dateRange: [
          { required: true, message: 'Date range is required' }
        ]
      },
      loading: false,
      pickerOptions: {
        disabledDate (time) {
          const dateTime = time.getTime()
          return dateTime > new Date((Date.now())).getTime()
        }
      }
    }
  },
  computed: {
    idcRoomsInfo () {
      var str = ''
      this.tableData.idcRooms.forEach(element => {
        str += element.name + '(' + element.idc_ids + ') '
      })
      console.log('str', str)
      return str
    }
  },
  mounted () {
    this.handleSearch()
    this.queryNationList()
  },
  methods: {
    handleSearch () {
      this.tableData.loading = true
      this.formData.page = 1
      this.queryDataList()
    },
    queryDataList () {
      this.$refs.searchForm.validate(valid => {
        if (valid) {
          const query = this.formatQuery(this.formData)
          delete query.countryCodeList
          sessionStorage.setItem(formKey, JSON.stringify(this.formData))
          querySpeedMeasurementDelay(query)
            .then(res => {
              if (res.code === 200) {
                console.log(res)
                this.tableData.list = res.data.list || []
                this.tableData.total = res.data.total || 0
                this.tableData.idcRooms = res.data.idc_rooms || []
              }
            })
            .finally(() => {
              this.tableData.loading = false
            })
        }
      })
    },
    formatQuery (formData) {
      const form = cloneDeep(formData)
      const [startTime, endTime] = form.dateRange
      delete form.dateRange
      return {
        ...form,
        startTime,
        endTime,
        nationCode: form.countryCodeList.map(item => {
          return item.countryCode
        })
      }
    },
    filterMethod (keyword) {
      this.filterNationList = keyword ? this.nationList.filter(item => {
        return item.nation.toLowerCase().startsWith(keyword.toLowerCase())
      }) : this.nationList
    },
    queryNationList () {
      return getUserNationStatistics({
        searchWord: '',
        pageNum: 1,
        pageSize: 1000
      }).then(res => {
        if (res.code === 200) {
          const sortNationList = (res.data.list || []).sort((a, b) => {
            if (a.nation < b.nation) {
              return -1
            }
            if (a.nation > b.nation) {
              return 1
            }
            return 0
          })
          this.nationList = sortNationList
          this.filterNationList = sortNationList
          this.formData.countryCodeList = this.nationList
        }
      })
    },
    changeSelectState (listKey, fullList, scope) {
      scope[listKey].length === fullList.length
        ? scope[listKey] = []
        : scope[listKey] = fullList
    },
    selectButtonLabel (currentList, fullList) {
      return currentList.length === fullList.length ? '取消全选' : '全选'
    }
  }
}
</script>

<style lang="less">
  .Dashboard-layout{
    .layout-content{
      display: flex;
      flex-direction: column;
      .wrapper{
        flex: 1;
        display: flex;
        .chart{
          flex: 1;
        }
      }
    }
  }
  .pre-header {
      height: 46px;
      .select-all {
        position: absolute;
        top: 0;
        z-index: 100;
        width: 100%;
        font-size: 14px;
        margin: 10px 0;
        padding: 10px 20px;
        cursor: pointer;
        background: #F5F7FA;
        box-sizing: border-box;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, .12);
        &::before {
          content: '';
          display: block;
          height: 10px;
          background: white;
          position: absolute;
          top: -10px;
          left: 0;
          width: 100%;
        }
        &:hover {
          color: cornflowerblue;
        }
      }
    }
    .change-icon{
    font-size:28px;
  }
</style>
